export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    code: 'common',
  },
  {
    title: 'Local Tips',
    icon: 'ImageIcon',
    code: 'post:list' || 'post:read',
    children: [
      {
        title: 'Local Tips List',
        route: 'post-manager',
        code: 'post:list' || 'post:read',
      },
      {
        title: 'Local Tips Category',
        route: 'post-category',
        code: 'postCategory:list' || 'postCategory:read',
      },
    ],
  },
  {
    title: 'Program',
    icon: 'CalendarIcon',
    code: 'program:list' || 'program:read',
    children: [
      {
        title: 'Program List',
        route: 'program-manager',
        code: 'program:list' || 'program:read',
      },
      {
        title: 'Program Category',
        route: 'program-category',
        code: 'programCategory:list' || 'programCategory:read',
      },
    ],
  },
  {
    title: 'Room Service',
    route: 'order-manager',
    icon: 'FileTextIcon',
    code: 'roomService:list' || 'roomService:read',
  },
  {
    title: 'Customer',
    route: 'customer-flow',
    icon: 'GitMergeIcon',
    code: 'customerFlow:list' || 'customerFlow:read',
  },
  {
    title: 'General Config',
    route: 'general-config',
    icon: 'TagIcon',
    code: 'general:list' || 'general:read',
  },
  {
    title: 'Personal Experience',
    route: 'pe-rule',
    icon: 'CommandIcon',
    code: 'rating:list' || 'rating:read',
  },
  {
    title: 'Rating Config',
    route: 'rating-config',
    icon: 'StarIcon',
    code: 'rating:list' || 'rating:read',
  },
  {
    title: 'User Manager',
    icon: 'UserIcon',
    code: 'account:list' || 'account:read',
    children: [
      {
        title: 'User List',
        route: 'user-manager',
        code: 'account:list' || 'account:read',
      },
      {
        title: 'Role Manager',
        route: 'role-manager',
        code: 'role:list' || 'role:read',
      },
    ],
  },
  {
    title: 'Statistic',
    icon: 'BarChart2Icon',
    code: 'tracking:list',
    children: [
      {
        title: 'Tracking User',
        route: 'tracking-user',
        code: 'tracking:list',
      },
      {
        title: 'Rating User',
        route: 'rating-user',
        code: 'tracking:list',
      },
    ],
  },
]
